@media (max-width: 1000px) {
  .home-footer-div.mr-5 {
    display: block !important;
    margin-bottom: 0.5em !important;
  }
}

.banner-footer {
  width: 100vw;
  height: 0.15em;
  background-color: #d73f09;
}

.home-footer {
  color: #fff;
  background-color: #000;
  flex-shrink: 0;
}

.home-footer a {
  color: #fff;
}

.home-footer-div {
  display: inline-block;
}