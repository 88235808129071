.content-container {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.page-table {
  table-layout:fixed;
  text-align: left;
  margin: 0 auto 2rem auto;
}

th, td {
  padding: 10px;
  min-width: 150px;
}

table {
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  width: 100%;
  overflow: hidden;
  padding: 1rem;
  background: grey;
  background: white;
}


table thead tr th {
  background: #f4f2f1;
  color: #706c6b;
  font-variant-caps: all-small-caps;
  font-weight: 500;
  font-size: 12pt;
  border-bottom: none;
  padding: 1rem 2rem;
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
}

table tbody tr td {
  vertical-align: middle;
  padding: 1rem 2rem;
}

.page-link-header {
  cursor:pointer;
  color: #007bff;

}

.page-link-header:hover {
  color: #0057b3;
  text-decoration:underline;
}

.page-link-created {
  color: #239023;
}

.page-info-container {
  border: none !important;
}

#title {
  text-transform: capitalize;
}

.page-list-organizer {
  display: grid;
  grid-template-columns: repeat(auto-fill, 400px);
  grid-gap: 100px;
  justify-content: center;
}

@media (max-width: 1500px) {
  .page-list-organizer {
    display: grid;
    grid-template-columns: repeat(auto-fill, 400px);
    grid-gap: 30px;
    justify-content: center;
  }
}