.generic-header-bar {
  background: rgb(214,214,214);
  background: linear-gradient(0deg, rgba(214,214,214,1) 0%, rgba(255,255,255,1) 100%);
  top: 1em;
  z-index: 998;
}

.request-date {
  color: #239023;
}

.request-table {
  table-layout: fixed;
  word-wrap: break-word;
}

.request-dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
}

.orange-dot {
  background-color: #d73f09;
}

.black-dot {
  background-color: #000;
}

.green-dot {
  background-color: #239023;
}

.request-check {
  cursor: initial;
  margin-right: 0.15em;
  margin-top: 0.4em;
}

.request-check:hover {
  cursor: initial;
}