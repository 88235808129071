.table-container {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.active-sort { 
  color: #d73f09;
}

.user-table {
  table-layout:fixed;
  text-align: left;
  margin: 0 auto 2rem auto;
}

table {
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  width: 100%;
  overflow: hidden;
  padding: 2rem !important;
  background: grey;
  background: white;
}


table thead tr th {
  background: #f4f2f1;
  color: #706c6b;
  font-variant-caps: all-small-caps;
  font-weight: 500;
  font-size: 12pt;
  border-bottom: none;
  padding: 1rem !important;
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
}

table tbody tr td {
  vertical-align: middle;
  padding: 1rem !important;
  word-wrap: break-word;
}