.dropdown-menu {
  max-height: calc(100vh - 1rem);
  overflow-y: auto;
}

/* ---------------------
    SCROLLBAR STYLE
----------------------- */

.dropdown-menu::-webkit-scrollbar-track {
  background-color:#fff;
  -webkit-box-shadow: inset 0 0 6px rgba(200,200,200,1);
  box-shadow: inset 0 0 3px rgba(90,90,90,1);
  border-radius: 10px;
}

.dropdown-menu::-webkit-scrollbar {
  width: 12px;
}

.dropdown-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(200,200,200,1);
  box-shadow: inset 0 0 3px rgba(90,90,90,1);
  border-radius: 10px;
}

.dropdown-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#fff;
  -webkit-box-shadow: inset 0 0 3px rgba(90,90,90,1);
  box-shadow: inset 0 0 3px rgba(90,90,90,1);
}