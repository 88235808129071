.spin-loader{
  visibility: "visible";
  position: fixed;
  margin-left: -75px;
  margin-bottom: 75px;
  left: 50%;
  bottom: 50%;
  width: 0;
  height: 0;
  z-index: 9999;
}