@media (max-width: 768px) {
  .nav-bar-main, .dropdown-nav, .dropdown-content {
    display: none;
    position: absolute;
  }
}

.mild-tab {
  color: #a2a2a2 !important;
}

.nav-bar-main {
  background-color: #000;
  color: #fff;
  white-space: nowrap;
}

.dropdown-nav {
  position: relative;
  border-left: 1px solid #565656;
  border-right: 1px solid #565656;
  color: #fff;
}

.dropdown-nav:hover {
  cursor: pointer;
  text-decoration: underline;
}

.dropdown-nav a:link { color: #fff }
.dropdown-nav a:visited { color: #fff }
.dropdown-nav a:hover { color: #fff }
.dropdown-nav a:active { color: #fff }

.dropdown-content, .sub-dropdown-content {
  position: absolute;
  background-color: #000;
  white-space: nowrap;
  color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 501;
}

.dropdown-size-1 {
  min-width: 300px;
}

.dropdown-size-2 {
  min-width: 500px;
}

.dropdown-size-3 {
  min-width: 650px;
}

.sub-dropdown-content {
  min-width:100%;
}

.dropdown-content {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.1s, opacity 0.1s;
}

.dropdown:hover .dropdown-content {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s;
  left: 0;
}

.sub-dropdown-content {
  visibility: hidden;
}

.sub-show {
  display: block;
  visibility: visible;
  position: absolute;
  white-space: nowrap;
  right: 100%;
  margin-top: -2.5rem;
}

.dropdown:hover .dropdown-content .navbar-item:hover {
  background-color: #565656;
}

.dropdown:hover .dropdown-content a:hover {
  text-decoration: none;
}

.navbar-item-text {
  color: #fff;
}

.nav-tab-hidden {
  display: none;
}

.dropdown-nav-col, .navbar-image-item {
  /* min-height: 200px !important; */
}

.cat-tag-img {
  max-width: 150px;
  max-height: 150px;
  width: 100px;
  height: 100px;
}
