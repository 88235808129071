@media (min-width: 769px) {
  .nav-hamburger {
    display: none;
    position: absolute;
  }
  .nav-header-title {
    font-size: 2em !important;
  }
  .page-header-title-container {
    margin-right: 1.4em;
  }
  .small-org, .small-industry {
    display: none !important;
    position: absolute !important;
  }
  .big-org, .big-industry {
    display: inline !important;
    position: static !important;
  }
  .title-break {
    display: none !important;
    position: absolute !important;
  }
}

.title-break {
  display: inline;
  position: static;
}

@media (max-width: 768px) {
  .osu-logo {
    display: none;
    position: absolute;
  }
  .nav-header-title {
    font-size: 1.5em !important;
  }
  .page-header-title-container {
    margin-left: 1em;
  }
  .small-org, .small-industry {
    display: inline !important;
    position: static !important;
  }
  .big-org, .big-industry {
    display: none !important;
    position: absolute !important;
  }
}

@media (max-width: 1470px) {
  .title-break {
    display: inline !important;
    position: static !important;
  }
}

@media (min-width: 768px) and (max-width: 950px) {
  .page-header-title-container {
    margin: 0;
  }
}

.navbar-header {
  background-color: #d73f09 !important;
}

.nav-hamburger {
  width: 3.5em !important;
  height: 3.5em !important;
  background-color: #C13908;
}

.mini-nav-header {
  background-color: #000;
  font-weight: 600;
  font-size: 1.1em;
}

.mini-nav-text {
  text-decoration: none !important;
}

.school-title {
  color: #d73f09;
}

.nav-hamburger .nav-hamburger-icon {
  color: #fff;
}

.nav-hamburger .nav-hamburger-icon:hover {
  color: #000;
}

.nav-header-title {
  display: inline;
  font-weight: 500;
  margin: 0;
  font-family: 'Open Sans', 'Arial', sans-serif, 'Helvetica', 'Verdana', 'Geneva', 'Trebuchet MS', 'Tahoma';
  color: #fff;
}

.nav-header-title:hover {
  cursor: pointer;
}

.nav-header-title-top {
  display: block;
  font-weight: 500;
  margin-bottom: 0.25em;
  font-weight: bold;
  font-size: 1.15em;
  font-family: 'Open Sans', 'Arial', sans-serif, 'Helvetica', 'Verdana', 'Geneva', 'Trebuchet MS', 'Tahoma';
  color: #fff;
}

.nav-header-title-bottom {
  display: block;
  font-weight: 500;
  margin: 0;
  font-size: 1.15em;
  font-family: 'Open Sans', 'Arial', sans-serif, 'Helvetica', 'Verdana', 'Geneva', 'Trebuchet MS', 'Tahoma';
  color: #fff;
}

.mobile-title {
  display: inline-block;
}

.nav-header-symbol {
  display: inline;
  font-size: 1.75rem;
  margin: 0;
  font-family: 'Open Sans', 'Arial', sans-serif, 'Helvetica', 'Verdana', 'Geneva', 'Trebuchet MS', 'Tahoma';
  color: #000;
}

.osu-logo {
  margin: 0 2em 0 2em;
  height: 4em;
  background-color: #d73f09;
  border: 0px;
}

.osu-logo:hover {
  cursor: pointer;
}

.nav-header-title-link:hover {
  text-decoration: none;
}