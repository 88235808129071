.generic-header-bar {
  background: rgb(214,214,214);
  background: linear-gradient(0deg, rgba(214,214,214,1) 0%, rgba(255,255,255,1) 100%);
  top: 1em;
  z-index: 998;
}

.active-sort { 
  color: #d73f09;
}

@media (min-width: 1200px) {
  .container {
    max-width: 70%;
  }
}