select {
  font-family: 'Font Awesome\ 5 Brands' , 'arial';
}

.modal-width {
  max-width: 65%;
}

.custom-control-label::before,
.custom-control-label::after {
  top: .15em !important;
  width: 1.25em !important;
  height: 1.25em !important;
}