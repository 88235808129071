select {
  font-family: 'Font Awesome\ 5 Brands' , 'arial';
}

.modal-width {
  max-width: 65%;
}

.created-text {
  color: #239023;
}

.description-review-page {
  white-space: pre-wrap;
}