i:hover {
	cursor: pointer;
}

.header-anchor {
	display: block;
	position: relative;
	top: -25px;
	visibility: hidden;
}

.filter-col {
	white-space: nowrap;
	overflow: hidden;
}

.header-approved {
	background: rgb(214, 214, 214);
	background: linear-gradient(
		0deg,
		rgba(214, 214, 214, 1) 0%,
		rgba(255, 255, 255, 1) 100%
	);
}

.header-review {
	background: rgb(244, 232, 171);
	background: linear-gradient(
		0deg,
		rgba(244, 232, 171, 1) 0%,
		rgba(254, 247, 213, 1) 100%
	);
}

.header-container {
	position: sticky;
	top: 0 !important;
}

/* lines are 4% darker than base color */
.header-internal.header-approved {
	background: linear-gradient(
			0deg,
			rgba(50, 50, 50, 0.2) 0%,
			rgba(255, 255, 255, 0.2) 100%
		),
		repeating-linear-gradient(
			90deg,
			#ffffff,
			#ffffff 8%,
			#f5f5f5 8%,
			#f5f5f5 16%
		);
}

/* lines are 5% darker than base color */
.header-internal.header-review {
	background: linear-gradient(
			0deg,
			rgba(50, 50, 50, 0.2) 0%,
			rgba(255, 255, 255, 0.2) 100%
		),
		repeating-linear-gradient(
			90deg,
			#fcf7db,
			#fcf7db 8%,
			#efeace 8%,
			#efeace 16%
		);
}

.isSticky {
	background: blue !important;
}
