.generic-header-bar {
  background: rgb(214,214,214);
  background: linear-gradient(0deg, rgba(214,214,214,1) 0%, rgba(255,255,255,1) 100%);
  top: 1em;
  z-index: 998;
}

body {
  background-color: #f8f9fa;
  font-family: 'Roboto', sans-serif;
}

@media (min-width: 1200px) {
  .container {
    max-width: 70%;
  }
}

.disclaimer-text {
  white-space: pre-line;
}

.answers-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
}

.submit-quiz-box {
  display: inline-block;
  width: 100%;
}