.comment-anchor {
  font-size: 0;
}

.request-card-comment-body {
  white-space: pre-wrap;
}

.request-icon-external {
  color: #9BAAB3;
}

.request-icon-orange {
  color: #d73f09;
}

.request-icon-black {
  color: #000;
}

.request-icon-comment {
  color: #16B7FF;
}

.request-icon-change {
  color: #8040bf;
}

.request-icon-new {
  color: #9BAAB3;
}

.comment-date-text {
  color: #239023;
}