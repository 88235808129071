html, body {
  height: 100% !important;
}

body {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
  min-height: 100vh;
}

.ql-size-small {
  font-size: 0.75em;
}

.ql-size-large {
  font-size: 1.5em;
}

.ql-size-huge {
  font-size: 2.5em;
}

/* custom button themes */
.btn-headerbtn1 {
  background-color: #f56d43;
  color: white;
}

.btn-headerbtn1:hover {
  background-color: #cf5d3a;
  color: white;
}

.btn-headerbtn1:focus {
  background-color: #cf5d3a;
  color: white;
  box-shadow: 0px 0px 0px 3px rgba(245, 109, 67, 0.5);
}

.btn-headerbtn2 {
  background-color: #AEA490;
  color: white;
  transition: 0.2s;
}

.btn-headerbtn2:hover {
  background-color: #938873;
  color: white;
}

.btn-headerbtn2:focus {
  background-color: #938873;
  color: white;
  box-shadow: 0px 0px 0px 3px rgba(173, 164, 144, 0.5);
}