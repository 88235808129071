.generic-header-bar {
  background: rgb(214,214,214);
  background: linear-gradient(0deg, rgba(214,214,214,1) 0%, rgba(255,255,255,1) 100%);
  top: 1em;
  z-index: 998;
}

body {
  background-color: #f8f9fa;
  font-family: 'Roboto', sans-serif;
}

@media (min-width: 1200px) {
  .container {
    max-width: 70%;
  }
}

.home-page-container a {
  outline: none;
  text-decoration: none;
  padding: 2px 1px 0;
}

.home-page-container img {
  border-style: none;
}

.allow-newlines {
  white-space: pre-wrap;
}