.generic-header-bar {
  background: rgb(214,214,214);
  background: linear-gradient(0deg, rgba(214,214,214,1) 0%, rgba(255,255,255,1) 100%);
  top: 1em;
  z-index: 998;
}

.banner-preview {
  max-width: 250px;
  max-height: 100px;
}

.prompt-container-home {
  border: 0;
}