.item-separator-table tbody tr td {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.item-separator-div {
  width: 100% !important;
  border: none !important;
  box-shadow: none !important;
  text-align: left !important;
  vertical-align: top !important;
  background: transparent !important;
  border-collapse: separate !important;
  border-spacing: 0 0 !important;
}

.div-indent-level-1 {
  margin-left: 1.5em;
}

.div-indent-level-2 {
  margin-left: 3em;
}

.div-indent-level-3 {
  margin-left: 4.5em;
}

.div-indent-level-4 {
  margin-left: 6em;
}