@media (min-width: 768px) {
  .page-desc-s {
    display: none;
  }
}

@media (max-width: 767px) {
  .page-desc {
    display: none;
  }
}

.page-approved.header-bar {
  background: rgb(214,214,214);
  background: linear-gradient(0deg, rgba(214,214,214,1) 0%, rgba(255,255,255,1) 100%);
}

.page-review {
  background-color: #fcf7db;
}

.page-review.header-bar {
  background: rgb(244,232,171);
  background: linear-gradient(0deg, rgba(244,232,171,1) 0%, rgba(254,247,213,1) 100%);
}

/* lines are 4% darker than base color */
.page-internal.page-approved.header-bar {
  background: linear-gradient(0deg, rgba(50,50,50,0.2) 0%, rgba(255,255,255,0.2) 100%),
  repeating-linear-gradient(
    90deg,
    #ffffff,
    #ffffff 8%,
    #f5f5f5 8%,
    #f5f5f5 16%
  );
}

/* lines are 5% darker than base color */
.page-internal.page-review.header-bar {
  background: linear-gradient(0deg, rgba(50,50,50,0.2) 0%, rgba(255,255,255,0.2) 100%),
  repeating-linear-gradient(
    90deg,
    #fcf7db,
    #fcf7db 8%,
    #efeace 8%,
    #efeace 16%
  );
}

.allow-newlines {
  white-space: pre-wrap;
}