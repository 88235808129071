a.page-sidebar-nav-link,
a:hover.page-sidebar-nav-link,
a:focus.page-sidebar-nav-link {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.sidebar-arrow-right {
  width: 0px;
  height: 0px;
  border-top: 8px solid transparent;
  border-left: 10px solid #fff;
  border-right: none;
  border-bottom: 8px solid transparent;
}

.sidebar-arrow-down {
  width: 0px;
  height: 0px;
  border-top: 10px solid #fff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: none;
}


.sidebar-icons {
  display: inline;
  white-space: nowrap;
}

.sidebarCollection {
  font-size: 1.3rem;
}

.sidebarCollection.view-toggle-button {
  font-size: 1.1rem;
}

.sidebarCollection.card-header {
  cursor: pointer;
  color: #ccc;
  background: #41484e !important;
  text-decoration: none;
  border-radius: 0 !important;
}

.sidebar-nav-link:hover {
  cursor: pointer;
  font-size: 1rem;
  color: #FFFFFF;
  background: #41484E;
  text-decoration: none;
}

div.sidebar-nav-link {
  font-size: 1rem;
  color: #FFFFFF;
  padding: 1rem;
  margin-top: -1.5rem;
  text-decoration: none;
}

.sidebarCollection.card-header:hover {
  cursor: pointer;
  color: #FFFFFF;
  background: #2d3236 !important;
  text-decoration: none;
}

.sidebarCollection.card-header:hover {
  text-decoration: none;
}

.page-sidebar-nav-link:hover {
  text-decoration: none;
}