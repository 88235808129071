#sidebar-header {
  background: #d73f09 !important;
  border-color: #d73f09!important;
  border-radius: 0;
}

.sidebar-page-container .card-header {
  background-color:#272b2e !important;
  border-width: 0;
}

.sidebar-page-container .active {
  color: #de6337;
  background: #41484e !important;
}

.sidebar-page-container .active:hover {
  color: #E4825F;
  background: #41484e !important;
}

#sidebar {
  background: #16191b !important;
}

#sidebar-body {
  background: #16191b;
  margin: 0;
  padding: 0;
}

#sidebar-header {
  border-color: #393b3c !important;
  border-width: 0 0 1px 0;
  margin: 0;
}

.sidebar-page-container {
  border-color: #393b3c !important;
  border-width: 0 0 1px 0;
  border-radius: 0 !important;
  cursor: "pointer";
  margin: 0;
}

.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

/* ---------------------------------------------------
    SIDEBAR STYLE Transition from left to right
----------------------------------------------------- */
#sidebar {
  width: 250px;
  position: fixed;
  top: 0;
  height: 100vh;
  z-index: 999;
  background: #343a40;
  color: #fff;
  transition: all 0.3s;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
}

.overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.overlay.active {
  display: block;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

/* ---------------------
    SCROLLBAR STYLE
----------------------- */
#sidebar::-webkit-scrollbar {
  width: 12px;
}

#sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(90,90,90,1);
  border-radius: 12px;
}

#sidebar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(76,83,89,1);
  -webkit-box-shadow: inset 0 0 6px rgba(255,255,255,0.15);
}
