.fa-disabled {
  opacity: 0.2;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
}

i[title] {
  display: block;
  color: black;
  animation: fadeIn 2s;
}

.filter-round {
  border-radius: 0.25em 0.25em 0.25em 0.25em;
}

.filter-corners {
  border-radius: 0;
}

.filter-btn {
  height: 1.75em;
}

.primary-filter-bar-container {
  position: relative;
  z-index: 1000 !important;
}

.dropdown-filter-icon-container {
  z-index: 1000 !important;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 25px;
  justify-content: left;
  right: 0;
}

@media (max-width: 882px) {
  .dropdown-filter-icon-container {
    right: auto;
  }
}