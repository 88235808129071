.btn-report-card {
  font-size: x-large;
  padding: 0 0.2em 0.1em 0.2em;
}

.report-card-special-text {
  color: #239023;
}

.report-card-span {
  font-weight: bold;
  font-size: xx-large;
}