.modal-inscreen {
  width: 1000px !important;
  max-width: 95% !important;
  height: auto !important;
}

.modal-expand {
  width: 3000px !important;
  max-width: 95% !important;
  height: auto !important;
}

.img-thumbnail {
  max-height: 10em !important;
  min-height: 5em !important;
  cursor: pointer;
}

.img-normal {
  max-height: 23em !important;
  cursor: pointer;
}