.page-card {
  display: grid;
  grid-template-columns: 300px;
  grid-template-rows: 210px 210px 20px;
  grid-template-areas: "image" "text" "stats";
  border-radius: 18px;
  background: white;
  box-shadow: 5px 5px 5px rgba(0,0,0,0.9);
  text-align: center;
  transition: 0.5s ease;
  cursor: pointer;
  margin:30px;
}

.card-image {
  grid-area: image;
}
.card-text {
  grid-area: text;
}

.card-image {
  grid-area: image;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-size: cover;
}

.page-card-container a:hover {
  text-decoration: none;
}

.card-text {
  color: #000;
  grid-area: text;
  margin: 25px;
}

.card-text .date {
  color: #d73f09;
  font-size:13px;
}
.card-text p {
  color: grey;
  font-size:15px;
  font-weight: 300;
}
.card-text h2 {
  margin-top:0px;
  font-size:28px;
}

.page-card:hover {
  transform: scale(1.15);
  box-shadow: 5px 5px 15px rgba(0,0,0,0.6);
}