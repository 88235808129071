.generic-header-bar {
  background: rgb(204, 155, 155);
  background: linear-gradient(0deg, rgba(214,214,214,1) 0%, rgba(255,255,255,1) 100%);
  top: 1em;
  z-index: 998;
}

body {
  background-color: #f8f9fa;
  font-family: 'Roboto', sans-serif;
}

@media (min-width: 1150px) {
  .container {
    max-width: 70%;
  }
}

.banner-container {
  position: relative;
  max-width: 100vw;
  text-align: center;
  overflow: hidden;
  background-color: #000;
}

.home-banner {
  display: block;
  max-height: 500px;
  max-width: 100vw;
  text-align: center;
  margin-left: 50%;
  transform: translateX(-50%);
  opacity: 0.7;
}

@media (max-width: 1650px) and (min-width: 1000px) {
  .banner-image-div {
    top: 15% !important;
    font-size: 1.5em !important;
  }
}

@media (max-width: 1150px) {
  .banner-image-div {
    top: 17% !important;
    font-size: 1.75em !important;
  }
}

.banner-image-div {
  position: absolute;
  top: 22%;
  left: 20%;
  text-align: left;
  font-family: georgia, serif;
  font-size: 2em;
}

.banner-image-text {
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 3%;
}

.banner-button {
  margin-top: 1em;
  color: #fff;
  background-color: #d73f09;
  border: 0;
  padding: 0.25em;
  border-radius: 3%;
  padding-left: 1em;
  padding-right: 1em;
}

.banner-button:hover {
  background-color: #c13808;
}

@media (max-width: 1150px) {
  .home-banner {
    max-height: 350px;
    max-width: 500%;
  }
}

.text-box-row {
  max-width: 100vw;
  margin: 0;
}

.home-content-block {
  text-align: center;
  white-space: wrap;
}

.home-inner-block {
  margin: auto;
  padding: 5em 2em 5em 2em;
  width: 70%;
}

.home-expanded-inner-block {
  margin: auto;
  padding: 5em 2em 5em 2em;
  width: 90%;
}

.home-page-list {
  padding-top: 3em;
  padding-bottom: 5em;
  background-color: #202020;
  overflow: hidden;
}

.light-home-text {
  color: #fff;
  padding-bottom: 1em;
  text-align: center;
}

.img-thumbnail-home {
  max-height: 10em !important;
  min-height: 5em !important;
  cursor: pointer;
}

.home-on {
  background-color: #fff;
}

.home-off {
  background-color: #eee;
}

.page-card-row {
  margin: auto;
}

.contributors-all {
  text-align: center;
}

@media (max-width: 768px) {
  .contributors-all {
    text-align: center !important;
  }
}