.card-body-approved {
  background-color: white;
}

.card-body-review {
  background-color: #fcf7db;
}

.card-body-invalid {
  background-color: #ffa6a6 !important;
}

.card-header-bar {
  background-color: #f7f7f7;
}

.card-header-bar.card-body-review {
  background-color: #efeace;
}

/* lines are 4% darker than base color */
.card-body-internal.card-body-approved.card-header-bar {
  background:
  repeating-linear-gradient(
    90deg,
    #f7f7f7,
    #f7f7f7 8%,
    #ebebeb 8%,
    #ebebeb 16%
  );
}

/* lines are 5% darker than base color */
.card-body-internal.card-body-review.card-header-bar {
  background:
  repeating-linear-gradient(
    90deg,
    #efeace,
    #efeace 8%,
    #e2ddc1 8%,
    #e2ddc1 16%
  );
}

.card-anchor {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}