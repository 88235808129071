#no-account-login {
  cursor:pointer;
  color: #007bff;
  background: none!important;
  border: none;
  padding: 0!important;
}

#no-account-login:hover {
  color: #0057b3;
  text-decoration:underline;
}

@media (min-width: 576px) {
  .modal-width {
    max-width: 65%;
  }
}

@media (max-width: 575px) {
  .modal-width {
    max-width: 100%;
  }
}