@media (min-width: 1200px) {
  .container {
    max-width: 70%;
  }
}

/* Cards/Categories */
.hide {
  z-index: 999;
  transform: scale(1, 0);
  position: absolute;
}

.active {
  transform: scale(1);
  transition: transform 0.4s;
}